import * as React from "react";
import { TaskListItem } from "./tasklistitem";
// const AuthenticatedApp = React.lazy(() =>
//   import(/* webpackPrefetch: true */ './authenticated-app'),
// )
// const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function TaskList({ allItems }) {
  const [items, setItems] = React.useState(allItems);

  function removeItem(item) {
    setItems(items.filter((i) => i.id !== item.id));
  }

  function handleKeyPress(event) {
    if (
      (event.key === "Enter" || event.charCode === 13) &&
      event.target.value != ""
    ) {
      setItems([{ id: "x", value: event.target.value }, ...items]);

      document.getElementById("placeholder").value = "";
    }
  }

  return (
    <div className="keys">
      <div className="mb-3 pt-0">
        <input
          id="placeholder"
          type="text"
          placeholder="Placeholder"
          className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          onKeyPress={handleKeyPress}
        />
      </div>

      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        {items.map((item) => (
          <TaskListItem item={item} removeItem={removeItem} />
        ))}
      </ul>
    </div>
  );
}

export { TaskList };

//          ref={this.textInput}
