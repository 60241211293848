import * as React from "react";

// const AuthenticatedApp = React.lazy(() =>
//   import(/* webpackPrefetch: true */ './authenticated-app'),
// )
// const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function Menu() {
  //const {user} = useAuth()
  return (
    //   <React.Suspense fallback={<FullPageSpinner />}>
    //     {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    //   </React.Suspense>
    // )
    <>menu here</>
  );
}

export { Menu };
