import * as React from "react";
import { Heading } from "./components/heading";
import { Menu } from "./components/menu";
import { ProfileLink } from "./components/profilelink";
import { TaskList } from "./components/tasklist";
// import { fetchPokemon } from "./apiClient";
import "./index.css";

import { Auth } from "aws-amplify";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./lib/contextLib";
import { onError } from "./lib/errorLib";
import { TkdoRouter } from "./routes";

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import axios from "axios"

function App({ signOut, user }) {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);
  const [isAuthenticated, userHasAuthenticated] = React.useState(false);
  const requireAuth = process.env.NODE_ENV === "prod";

  React.useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {

    if (requireAuth) {
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
      } catch (e) {
        if (e !== "No current user") {
          onError(e);
        }
      }
    } else {
      userHasAuthenticated(true);
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push("/login");
  }

  console.log(
    "isAuthenticating: " +
      isAuthenticating +
      ", isAuthenticated: " +
      isAuthenticated
  );
  if(isAuthenticated) {
    Auth.currentSession().then(res=>{
      let token = res.getIdToken().getJwtToken()
      //You can print them to see the full objects
      console.log(`auth token: ${token}`)

      let url = "http://localhost:7056";
      let endpoint = "/hc";
      const isNotLocal = process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "test";
      if(isNotLocal) {
        url = process.env.REACT_APP_API;
      }
      axios.get(url+endpoint, {
        headers: {
          'Authorization': token
        }
      })
        .then(res => {
          console.log(JSON.stringify(res));
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
  })
  }

  //<button onClick={signOut}>Sign out</button>
  return (
    !isAuthenticating && (
      <div className="App container py-3">
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <TkdoRouter />
        </AppContext.Provider>
      </div>
    )
  );
}

export default withAuthenticator(App);

// !isAuthenticating && (
//   <div className="App container py-3">
//     <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
//       <LinkContainer to="/">
//         <Navbar.Brand className="font-weight-bold text-muted">
//           Scratch
//         </Navbar.Brand>
//       </LinkContainer>
//       <Navbar.Toggle />
//       <Navbar.Collapse className="justify-content-end">
//         <Nav activeKey={window.location.pathname}>
//           {isAuthenticated ? (
//             <>
//               <LinkContainer to="/settings">
//                 <Nav.Link>Settings</Nav.Link>
//               </LinkContainer>
//               <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
//             </>
//           ) : (
//             <>
//               <LinkContainer to="/signup">
//                 <Nav.Link>Signup</Nav.Link>
//               </LinkContainer>
//               <LinkContainer to="/login">
//                 <Nav.Link>Login</Nav.Link>
//               </LinkContainer>
//             </>
//           )}
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//     <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
//       <TkdoRouter />
//     </AppContext.Provider>
//   </div>
