import React from "react";
import { Route, Switch } from "react-router-dom";
import { TaskListPage as Home } from "./page/tasklist";
//import Login from "./containers/Login";
// import Notes from "./containers/Notes";
// import Signup from "./containers/Signup";
// import NewNote from "./containers/NewNote";
// import Settings from "./containers/Settings";
import { NotFound } from "./page/notfound";
// import AuthenticatedRoute from "./components/AuthenticatedRoute";
// import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

function TkdoRouter() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export { TkdoRouter };

// <UnauthenticatedRoute exact path="/login">
//   <Login />
// </UnauthenticatedRoute>
// <UnauthenticatedRoute exact path="/signup">
//   <Signup />
// </UnauthenticatedRoute>
// <AuthenticatedRoute exact path="/settings">
//   <Settings />
// </AuthenticatedRoute>
// <AuthenticatedRoute exact path="/notes/new">
//   <NewNote />
// </AuthenticatedRoute>
// <AuthenticatedRoute exact path="/notes/:id">
//   <Notes />
// </AuthenticatedRoute>
