import * as React from "react";
import { CheckBox, TextBox } from "./common/parts";

function TaskListItem({ item, removeItem }) {
  return (
    <li key={item.id}>
      <div>
        <CheckBox />
        <TextBox desc={item.value} id={item.id} />
      </div>
    </li>
  );
}

export { TaskListItem };

// <li key={item.id}>
//   <button onClick={() => removeItem(item)}>remove</button>{" "}
//   <label htmlFor={`${item.id}-input`}>{item.value}</label>{" "}
//   <input id={`${item.id}-input`} defaultValue={item.value} />
// </li>
