import * as React from "react";
import { Heading } from "../components/heading";
import { Menu } from "../components/menu";
import { ProfileLink } from "../components/profilelink";
import { TaskList } from "../components/tasklist";
// import { fetchPokemon } from "./apiClient";
import "../index.css";

// const AuthenticatedApp = React.lazy(() =>
//   import(/* webpackPrefetch: true */ './authenticated-app'),
// )
// const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

const allItems = [];

function TaskListPage() {
  // React.useEffect(() => {
  //   fetchPokemon().then(
  //     () => {
  //       alert("pokemon?");
  //       // setState({status: 'resolved', pokemon})
  //     },
  //     (error) => {
  //       alert("error?" + error);
  //       //setState({status: 'rejected', error})
  //     }
  //   );
  // }, []);

  return (
    <React.Fragment>
      <div>
        <div class="grid grid-cols-3 gap-4">
          <div class="bg-green-500 rounded">
            <Menu />
          </div>
          <div class="bg-green-500 rounded">
            <Heading />
          </div>
          <div class="bg-green-500 rounded">
            <ProfileLink />
          </div>
        </div>
        <div class="bg-green-100 rounded">
          <TaskList allItems={allItems} />
        </div>
      </div>
    </React.Fragment>
  );
}

export { TaskListPage };

// function App() {
//   //const {user} = useAuth()
//   return (
//     //   <React.Suspense fallback={<FullPageSpinner />}>
//     //     {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
//     //   </React.Suspense>
//     // )
//     <>
//       my app goes here
//       <Menu />
//       // <Heading />
//       // <ProfileLink />
//       // <TaskList />
//     </>
//   );
