import * as React from "react";

function FullPageSpinner() {
  return (
    //   <React.Suspense fallback={<FullPageSpinner />}>
    //     {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    //   </React.Suspense>
    // )
    <div>LOADING....</div>
  );
}

export { FullPageSpinner };
