import * as React from "react";
import { MDCFormField } from "@material/form-field";
import { MDCCheckbox } from "@material/checkbox";
import { MDCTextField } from "@material/textfield";

function TextBox({ desc, id }) {
  return (
    <label
      class="mdc-text-field mdc-text-field--filled mdc-text-field--no-label"
      id={id}
    >
      <span class="mdc-text-field__ripple"></span>
      <input
        class="mdc-text-field__input"
        type="text"
        placeholder="Placeholder text"
        aria-label="Label"
        value={desc}
      />
      <span class="mdc-line-ripple"></span>
    </label>
  );
}

function CheckBox({ desc, id }) {
  return (
    <div class="mdc-form-field">
      <div class="mdc-checkbox">
        <input
          type="checkbox"
          class="mdc-checkbox__native-control"
          id="checkbox-1"
        />
        <div class="mdc-checkbox__background">
          <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path
              class="mdc-checkbox__checkmark-path"
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"
            />
          </svg>
          <div class="mdc-checkbox__mixedmark"></div>
        </div>
        <div class="mdc-checkbox__ripple"></div>
      </div>
    </div>
  );
}

export { CheckBox, TextBox };
